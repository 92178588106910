/* Mixins */
.space-tree-component {
  min-width: 960px;
  min-height: 100%;
}
.space-tree-component .page-body {
  margin: 24px auto;
  width: 60%;
}
.space-tree-component .space-tree {
  width: 100%;
  background-color: #fff;
}
.space-tree-component .space-tree .ant-tree-node-content-wrapper {
  width: calc(100% - 30px);
}
.space-tree-component .space-tree .tree-item-action-wrapper {
  display: inline-block;
  float: right;
  margin-left: 40px;
}
.space-tree-component .space-tree .tree-item-input-name {
  width: 200px;
  vertical-align: top;
}